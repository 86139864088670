<template>
  <div>
    <div style="padding-top: 87px; padding-bottom: 108px">
      <!-- <mytitle title="关于百丽春护肤品" FiexText="ABOUT BAILICHUN"></mytitle> -->
      <mytitle title="关于我们" FiexText="ABOUT BAILICHUN"></mytitle>
    </div>
    <div class="research">
      <div class="researchcontent">
        <div class="hrandbox1">
          <img src="../../assets/img/brand/1.png" alt="" style="width: 449px;height:282px" />
          <div class="hrandbox1content">
            <div class="hrandbox1contenttitle">品牌介绍</div>
            <div class="hrandbox1contenttext">
              BAILICHUN为 深圳市前海胶粘天下信息科技有限公司公司 旗下
              瑞⼠进⼝护肤品牌，是以功效性成分为主打的诊疗品牌。集国际顶级原料供应商及世界重点科技实验室经理论研发、临床试验、数据分析后，综合评估达药妆级别轻奢护理品。在中国于2008年成⽴中国区科研基地，采⽤全进⼝配⽅专利功效原料，致⼒于⽪炎、祛痘，美⽩、修护，抗衰、以及其它问题肌肤产品研发及⽣产销售为⼀体，以“净排、修复，诊疗”为护肤理念，为各类肌肤⼈群研发安全、天然、⾼效的功效圣品。
            </div>
            <div class="hrandbox1dec">
              简单，高效，无添加是我们对品质的卓越追求
            </div>
          </div>
        </div>
        <div class="hrandbox2">
          <div class="hrandbox2title">药妆化妆品</div>
          <div class="hrandbox2text">
            产品严格按照制药标准生产，皮肤外用药品生产工艺生产，也称医学护肤品。经皮肤科生测试不含激素及皮肤依赖性成分，具有低致敏性和耐受性好的特性。产品的配方完全公开而且不含公认致敏原，所含的有效成分都经过皮肤科临床测试，保证产品安全品质。
          </div>
          <img src="../../assets/img/brand/2.png" alt="" style="width: 100%" />
        </div>
        <div class="hrandbox3">
          <div >
            <div class="hrandbox3contenttitle">产品特点</div>
            <div style="margin-top:24px">
              <div class="hrandbox3boxtitle">1、功效性</div>
              <div class="hrandbox3contentdec">
                主要根据不同类型皮肤的生理特点及皮肤病的发病机制进行活性成分的功能研究，其机制明显，对一些皮肤病能起到辅助治疗的作用
              </div>
            </div>
            <div style="margin-top:24px">
              <div class="hrandbox3boxtitle">2、安全性</div>
              <div class="hrandbox3contentdec">
                配⽅精简，各种原料经过严格筛选，不含操作⽪肤或引起⽪肤过敏的物质如⾊素、⾹料、致敏防腐剂等，植物萃取，对⽪肤⽆刺激婴⼉孕妇可⽤。按照GMP标准进行生产，具有良好的安全性。
              </div>
            </div>
            <div style="margin-top:24px">
              <div class="hrandbox3boxtitle">3、专业性</div>
              <div class="hrandbox3contentdec">
                专业的萃取技术以及独特的⾼科技优质配⽅，加上国际⼀流的⽣产设备并且有多项专利成分以及欧盟有机认证（ECOCERT SA)。
              </div>
            </div>
          </div>
          <img src="../../assets/img/brand/3.png" alt="" style="width: 510px;height:371px" />
        </div>
      </div>
    </div>

    <div>
      <foolerBar></foolerBar>
    </div>
  </div>
</template>

<script>
import mytitle from "../../components/title.vue";

import foolerBar from "../../components/foolerBar.vue";
export default {
  components: {
    foolerBar,
    mytitle,
  },
  methods: {},
  data() {
    return {
      imgData: [
        require("@/assets/img/homePage/banner.jpg"),
        require("@/assets/img/homePage/banner(1).jpg"),
        require("@/assets/img/homePage/banner(2).jpg"),
        require("@/assets/img/homePage/banner(3).jpg"),
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.research {
  display: flex;
  justify-content: center;
  .researchcontent {
    width: 1180px;
    .hrandbox1 {
      display: flex;
      justify-content: space-between;
      .hrandbox1content {
        width: 655px;
        .hrandbox1contenttitle {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c49f6f;
          width: 144px;
          padding-bottom: 10px;
          border-bottom: 1px solid #c49f6f;
        }
        .hrandbox1contenttext {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #666666;
          line-height: 30px;
          margin-top: 23px;
        }
        .hrandbox1dec {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #666666;
          line-height: 30px;
          margin-top: 23px;
        }
      }
    }
    .hrandbox2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 136px;
      .hrandbox2title {
        text-align: center;
        width: 216px;
        padding-bottom: 14px;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c49f6f;
        border-bottom: 1px solid #c49f6f;
      }
      .hrandbox2text {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #666666;
        line-height: 36px;
        margin-top: 58px;
        margin-bottom: 58px;
      }
    }
    .hrandbox3 {
      display: flex;
      justify-content: space-between;
      margin-top: 135px;
      margin-bottom: 177px;
      .hrandbox3contenttitle {
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c49f6f;
        width: 144px;
        padding-bottom: 10px;
        border-bottom: 1px solid #c49f6f;
      }
      .hrandbox3boxtitle {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
      .hrandbox3contentdec {
        width: 587px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #666666;
        line-height: 30px;
      }
    }
  }
}
</style>>


