<template>
  <div class="skipBox">
    <div
      :class="['defaultClass', $store.state.num == index ? 'selectClass' : '']"
      v-for="(item, index) in data"
      :key="index"
      @click="clickFn(index, item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          name: "首页",
          path: "homePage",
        },
        {
          name: "雪绒花系列",
          path: "edelweiss",
        },
        {
          name: "铂金系列",
          path: "platinum",
        },
        {
          name: "依克多因",
          path: "Ectoin",
        },
        {
          name: "研发及生产工艺",
          path: "research",
        },
        {
          name: "品牌介绍",
          path: "brand",
        },
      ],
    };
  },
  created() {
    if (window.localStorage.getItem('num')) {
      this.$store.commit("changeSkipBarNum", window.localStorage.getItem('num'));
    }
  },
  methods: {
    clickFn(index, item) {
      this.$store.commit("changeSkipBarNum", index);
      window.localStorage.setItem("num", index);
      this.$router.push({
        path: item.path,
      });
    },
  },
};
</script>

<style>
.skipBox {
  display: flex;
  align-items: center;
}
.defaultClass {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #666666;
  cursor: pointer;
  margin-right: 46px;
  padding: 10px 0;
}
.selectClass {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c49f6f;

  border-bottom: 1px solid #c49f6f;
}
</style>
