<template>
  <div>
    <sweiper :data="imgData"></sweiper>
    <div class="ectoinBox">
      <div class="ectoinBoxcontent">
        <div class="one">
          <div class="left">
            <div class="text">一、依克多因—重要安全成分</div>
            <div class="dec">
              &lt;依克多因>又称“耐盐菌萃取液”，来源于高嗜盐菌，在高盐、高温高紫外线辐射的极端条件下，依克多因使嗜盐菌免受伤害。
            </div>
          </div>
          <div class="right">
            <img
              src="../../assets/img/Ectoin/1.png"
              alt=""
              style="width: 572px; height: 348px"
            />
          </div>
        </div>
        <div class="two">
          <div class="title">⼆、为什么护肤品涂再多都⽆效？不吸收？</div>
          <div class="twocontent">
            <div class="twocontentList">
              <span class="twocontentListicon"><div>1</div></span>
              <span class="twocontentListtext">角质层受损</span>
            </div>
            <div class="twocontentList twocontentListPadding">
              <span class="twocontentListicon"><div>2</div></span>
              <span class="twocontentListtext">肌肤屏障受损</span>
            </div>
            <div class="twocontentList">
              <span class="twocontentListicon"><div>3</div></span>
              <span class="twocontentListtext">出现肌肤营养成分不吸收</span>
            </div>
          </div>
          <div class="twocontentText">
            依克多因静肤精华霜修护在角质区被吸收，修护角质层
          </div>
        </div>
        <div class="two">
          <div class="title" style="width: 183px">三、技术</div>
          <div class="twocontentText">
            缓释释放技术，当面霜作用在我们的肌肤上，养分缓慢释放，让肌肤温和吸收
          </div>
        </div>
        <div class="three">
          <div class="threeTitle">
            <div class="threeTitlediv">四、功效性特点</div>
          </div>
          <div class="threecontent1">
            <div style="box-shadow: 0px 0px 10px #d6d6d6;height:280px">
              <img
                src="../../assets/img/Ectoin/2.png"
                alt=""
                style="width: 380px"
              />
            </div>

            <div class="threecontent1text">
              使⽪肤能有效对抗微⽣物及过敏原的⼊侵。
            </div>
            <div style="box-shadow: 0px 0px 10px #d6d6d6;height:280px">
              <img
                src="../../assets/img/Ectoin/3.png"
                alt=""
                style="width: 380px"
              />
            </div>
          </div>
          <div class="threecontent2">
            <div class="threecontent2Box">
              <div class="threecontent2BoxText">
                能有效提升皮肤细胞的免疫防护能力, 增加细胞修复能力。
              </div>
            </div>
            <div style="box-shadow: 0px 0px 10px #d6d6d6;height:280px">
              <img
                src="../../assets/img/Ectoin/4.png"
                alt=""
                style="width: 380px"
              />
            </div>

            <div class="threecontent2Box">
              <div class="threecontent2BoxText">
                能有效提升皮肤细胞的免疫防护能力, 增加细胞修复能力。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <foolerBar></foolerBar>
    </div>
  </div>
</template>

<script>
import sweiper from "../../components/sweiper.vue";
import foolerBar from "../../components/foolerBar.vue";
export default {
  components: {
    sweiper,
    foolerBar,
  },
  methods: {},
  data() {
    return {
      imgData: [
        require("@/assets/img/homePage/banner.jpg"),
        require("@/assets/img/homePage/banner(1).jpg"),
        require("@/assets/img/homePage/banner(2).jpg"),
        require("@/assets/img/homePage/banner(3).jpg"),
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.ectoinBox {
  display: flex;
  justify-content: center;
  .ectoinBoxcontent {
    width: 1180px;
    padding-top: 88px;
    padding-bottom: 143px;
    .one {
      display: flex;
      justify-content: space-between;
      .text {
        width: 400px;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c49f6f;
        line-height: 30px;
        border-bottom: 1px solid #c49f6f;
        padding: 14px 0;
      }
      .dec {
        margin-top: 35px;
        width: 456px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #666666;
        line-height: 36px;
      }
    }
    .two {
      box-sizing: border-box;
      padding-top: 22px;
      margin-top: 108px;

      box-shadow: 0px 0px 10px #d6d6d6;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        width: 588px;
        text-align: center;
        padding: 14px 0;
        border-bottom: 1px solid #c49f6f;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #c49f6f;
      }
      .twocontent {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        .twocontentList {
          display: flex;
          align-items: center;
          .twocontentListicon {
            display: inline-block;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #c49f6f;
            display: flex;
            align-items: center;
            padding-left: 6px;
            box-sizing: border-box;
            div {
              text-align: center;
              font-size: 23px;
              font-family: Microsoft YaHei;
              font-weight: 200;
              font-style: italic;
              color: #ffffff;
            }
          }
          .twocontentListtext {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 36px;
            padding-left: 11px;
          }
        }
        .twocontentListPadding {
          margin-left: 40px;
          margin-right: 40px;
        }
      }
      .twocontentText {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #666666;
        line-height: 36px;
        margin-top: 40px;
        margin-bottom: 48px;
      }
    }
    .three {
      margin-top: 108px;
      .threeTitle {
        display: flex;
        justify-content: center;
        .threeTitlediv {
          text-align: center;
          width: 265px;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #c49f6f;
          line-height: 30px;
          border-bottom: 1px solid #c49f6f;
          padding: 14px 0;
        }
      }
      .threecontent1 {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .threecontent1text {
          width: 272px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #666666;
          line-height: 36px;
        }
      }
      .threecontent2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .threecontent2Box {
          width: 33.3%;
          display: flex;
          justify-content: center;
          .threecontent2BoxText {
            width: 275px;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #666666;
            line-height: 36px;
          }
        }
      }
    }
  }
}
</style>>


