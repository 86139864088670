//引入vue
import Vue from 'vue';
//引入vue-router
import VueRouter from 'vue-router';
//第三方库需要use一下才能用
Vue.use(VueRouter)
import homePage from "../page/homePage/index.vue"
import edelweiss from "../page/edelweiss/index.vue"
import platinum from "../page/platinum/index.vue"
import Ectoin from "../page/Ectoin/index.vue"
import research from "../page/research/index.vue"
import brand from "../page/brand/index.vue"

//定义routes路由的集合，数组类型
const routes=[
    //单个路由均为对象类型，path代表的是路径，component代表组件
    {path:'',redirect:"homePage"},
    {path:'/homePage',component: homePage},
    {path:'/edelweiss',component: edelweiss},
    {path:'/platinum',component: platinum},
    {path:'/Ectoin',component: Ectoin},
    {path:'/research',component: research},
    {path:'/brand',component: brand},
]

//实例化VueRouter并将routes添加进去
const router=new VueRouter({
//ES6简写，等于routes：routes
    routes
});

//抛出这个这个实例对象方便外部读取以及访问
export default router