<template>
  <div class="foolerBox">
    <div class="foolerBox_banxin">
      <div class="foolerBox_jump">
        <div
          class="foolerBox_jump_box"
          v-for="(item, index) in data"
          :key="index"
          @click="jump(item.path, index + 1)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="foolerBox_content">
        <div>
          <!-- <div class="foolerBox_content1">
            客户服务热线 | 400-8888-888
            <span style="width: 27px; display: inline-block"></span>
            邮箱：bailichunhufu@bly.com
          </div> -->
          <!-- <div class="foolerBox_content1">
            Copyright 2021版权所有 深圳中百生物科技有限公司
            <span class="beian" @click="beianClick">粤ICP备17120307号-2</span>
          </div> -->
          <div class="foolerBox_content1">
            Copyright 2024版权所有 深圳市前海胶粘天下信息科技有限公司
            <span class="beian" @click="beianClick">粤ICP备17120307号-2</span>
          </div>
        </div>
        <div style="display: flex">
          <el-popover
            placement="top"
            width="160"
            v-model="visible"
            trigger="hover"
          >
            <img src="../assets/img/xcx.jpg" alt="" style="width: 160px" />
            <img
              src="../assets/img/homePage/wx.png"
              slot="reference"
              style="width: 36px; cursor: pointer"
            />
          </el-popover>
          <div style="width: 24px"></div>
          <el-popover
            placement="top"
            width="160"
            v-model="visible2"
            trigger="hover"
          >
            <img src="../assets/img/gzh.jpg" alt="" style="width: 160px" />
            <img
              src="../assets/img/homePage/xcx.png"
              slot="reference"
              style="width: 36px; cursor: pointer"
            />
          </el-popover>
          <div style="width: 24px"></div>
          <div @click="click">
            <el-popover
              placement="top"
              width="160"
              v-model="visible1"
              trigger="hover"
            >
              <img src="../assets/img/dyimg.jpg" alt="" style="width: 160px" />
              <img
                src="../assets/img/dy.png"
                slot="reference"
                style="width: 36px; cursor: pointer"
              />
            </el-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          name: "雪绒花系列",
          path: "edelweiss",
        },
        {
          name: "铂⾦系列",
          path: "platinum",
        },
        {
          name: "依克多因",
          path: "Ectoin",
        },
        {
          name: "研发及⽣产⼯艺",
          path: "research",
        },
        {
          name: "品牌介绍",
          path: "brand",
        },
      ],
      visible: false,
      visible1: false,
      visible2: false,
    };
  },
  methods: {
    jump(path, index) {
      // let routeData = this.$router.resolve({ path: path });
      // window.open(routeData.href, "_blank");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$store.commit("changeSkipBarNum", index);
      window.localStorage.setItem("num", index);
      this.$router.push({
        path: path,
      });
    },
    click() {
      window.open(
        "https://www.douyin.com/user/MS4wLjABAAAAqwpEZ1WduNF5jN6VId8ikvwydUMqQnEzOYYWG38suc5SWNZW56Zg_LYuoOo2jfpb?extra_params=%7B%22search_id%22%3A%222021080917130901021219513800005858%22%2C%22search_result_id%22%3A%221390214053896590%22%2C%22search_keyword%22%3A%22%E7%99%BE%E4%B8%BD%E6%98%A5%22%2C%22search_type%22%3A%22user%22%7D&enter_method=search_result&enter_from=search_result"
      );
    },
    beianClick(){
      window.open('https://beian.miit.gov.cn/')
    }
  },
};
</script>

<style>
.foolerBox {
  height: 150px;
  background-color: #c49f6f;
  display: flex;
  justify-content: center;
}
.foolerBox_banxin {
  width: 1144px;
}
.foolerBox_jump {
  display: flex;
  justify-content: center;
  margin-top: 27px;
  margin-bottom: 31px;
}
.foolerBox_jump_box {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin-right: 15px;
  cursor: pointer;
}
.foolerBox_content {
  display: flex;
  justify-content: space-between;
}
.foolerBox_content1 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}
.beian:hover{
  cursor: pointer;
  color: #000;
}
</style>
