<template>
  <div>
    <sweiper :data="imgData"></sweiper>
    <div class="homePageTop">
      <div class="homePageTopContent">
        <div class="homePageTopContent1">
          <div class="homePageTopContent1_title">我们的理念</div>
          <div class="homePageTopContent1_english">The concept of Belichun</div>
          <div class="homePageTopContent1_content">
            BAILICHUN为 深圳市前海胶粘天下信息科技有限公司公司公司旗下
            瑞⼠进⼝护肤品牌，是以功效性成分为主打的诊疗品牌。集国际顶级原料供应商及世界重点科技实验室经理论研发、临床试验、数据分析后，综合评估达药妆级别轻奢护理品。在中国于2008年成⽴中国区科研基地，采⽤全进⼝配⽅专利功效原料，
            致⼒于⽪炎、祛痘，美⽩、修护，抗衰、以及其它问题肌肤产品研发及⽣产销售为⼀体，以“净排、修复，诊疗”为护肤理念，为各类肌肤⼈群研发安全、天然、⾼效的功效圣品。
          </div>
          <div class="homePageTopContent1_end">
            简单，⾼效，⽆添加是我们对品质的卓越要求。
          </div>
          <div class="homePageTopContent1_img">
            <img
              src="../../assets/img/homePage/title1.png"
              alt=""
              style="width: 100%"
            />
          </div>
        </div>
        <div class="homePageTopContent2">
          <div class="homePageTopContent1 boxPaddingLeft">
            <div class="homePageTopContent1_title">瑞⼠药⼚专利</div>
            <div class="homePageTopContent1_english">
              Swiss drug factory patent
            </div>
            <div class="homePageTopContent1_end">
              进⼝⼩⽩菊-⼩⽩菊内脂-抗炎独具⼀格、⽊兰醇-防⽌光⽼化。
            </div>
            <div class="homePageTopContent2_img">
              <img
                src="../../assets/img/homePage/title2.png"
                alt=""
                style="width: 100%"
              />
            </div>
          </div>
        </div>
        <div style="padding-top: 100px; padding-bottom: 80px">
          <mytitle title="明星产品" FiexText="Best Sellers"></mytitle>
        </div>
      </div>
    </div>
    <div class="productBoxfather">
      <div style="width: 1144px">
        <div class="productBox">
          <div>
            <img
              src="../../assets/img/homePage/sku1.png"
              alt=""
              style="width: 556px; height: 358px"
            />
          </div>
          <div style="margin-left: 32px">
            <div class="productBoxRight_text">依克多因静肤精华霜</div>
            <div class="productBoxRight_dsc">快速缓解⽪肤问题</div>
            <div class="productBoxRight_content">
              能有效提升⽪肤细胞的免疫防护能⼒,
              增加细胞修复能⼒能有效对抗微⽣物及过敏原的⼊侵，15分钟快速缓解（堪称急救霜！）和修护泛红、过敏的肌肤。⼤程度的对抗紫外线对⽪肤的伤害，修复紫外线导致的细胞DNA损伤，抗光⽼化DNA损伤，抗光⽼化。
            </div>
            <div class="productBoxRight_btn" @click="jump('Ectoin', 3)">
              查看更多>
            </div>
            <div class="productBoxRight_tag">
              <div class="productBoxRight_tag_box">
                <img
                  src="../../assets/img/homePage/tag1.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">提升防护</div>
              </div>
              <div class="productBoxRight_tag_box productBoxRight_tag_box_pad">
                <img
                  src="../../assets/img/homePage/tag2.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">提升修复</div>
              </div>
              <div class="productBoxRight_tag_box">
                <img
                  src="../../assets/img/homePage/tag3.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">抗光老化</div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 108px"></div>
        <div class="productBox">
          <div>
            <div class="productBoxRight_text">雪绒花舒润修护⾯膜</div>
            <div class="productBoxRight_dsc">持久养润肌肤</div>
            <div class="productBoxRight_content">
              采用渗透超纯水作为载体，日本进口384蚕丝布，各知名皮肤科临床数据实验统计分析，超强补水力与修护力，一片改善。
            </div>
            <div class="productBoxRight_btn" @click="jump('edelweiss', 1)">
              查看更多>
            </div>
            <div class="productBoxRight_tag">
              <div class="productBoxRight_tag_box">
                <img
                  src="../../assets/img/homePage/tag4.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">深度补水</div>
              </div>
              <div class="productBoxRight_tag_box productBoxRight_tag_box_pad">
                <img
                  src="../../assets/img/homePage/tag5.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">提高免疫力</div>
              </div>
              <div class="productBoxRight_tag_box">
                <img
                  src="../../assets/img/homePage/tag6.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">抗炎抗敏</div>
              </div>
            </div>
          </div>
          <div style="margin-left: 32px">
            <img
              src="../../assets/img/homePage/sku2.png"
              alt=""
              style="width: 556px; height: 358px"
            />
          </div>
        </div>
        <div style="height: 108px"></div>
        <div class="productBox">
          <div>
            <img
              src="../../assets/img/homePage/sku3.png"
              alt=""
              style="width: 556px; height: 358px"
            />
          </div>
          <div style="margin-left: 32px">
            <div class="productBoxRight_text">透⽔导流精华液</div>
            <div class="productBoxRight_dsc">深补⽔、强吸收、⾼保湿</div>
            <div class="productBoxRight_content">
              直达肌底渗透⼒，密集滋养，提⾼肌底含⽔量，增强肌肤保⽔⼒，层层锁⽔缓解肌肤⼲燥、紧绷、细纹，透舒缓滋养肌肤3D⽴体胞间补⽔、复苏活⼒美肌。
            </div>
            <div class="productBoxRight_btn" @click="jump('platinum', 2)">
              查看更多>
            </div>
            <div class="productBoxRight_tag">
              <div class="productBoxRight_tag_box">
                <img
                  src="../../assets/img/homePage/tag4.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">深度补水</div>
              </div>
              <div class="productBoxRight_tag_box productBoxRight_tag_box_pad">
                <img
                  src="../../assets/img/homePage/tag7.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">超强吸收</div>
              </div>
              <div class="productBoxRight_tag_box">
                <img
                  src="../../assets/img/homePage/tag8.png"
                  alt=""
                  style="width: 64px"
                />
                <div class="productBoxRight_tag_box_text">高效保湿</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 126px"></div>
    <div>
      <foolerBar></foolerBar>
    </div>
  </div>
</template>

<script>
import sweiper from "../../components/sweiper.vue";
import mytitle from "../../components/title.vue";
import foolerBar from "../../components/foolerBar.vue";
export default {
  components: {
    sweiper,
    mytitle,
    foolerBar,
  },
  created() {},
  mounted() {},
  methods: {
    jump(path, index) {
      // let routeData = this.$router.resolve({ path: path });
      // window.open(routeData.href, "_blank");
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$store.commit("changeSkipBarNum", index);
      window.localStorage.setItem("num", index);
      this.$router.push({
        path: path,
      });
    },
  },
  data() {
    return {
      imgData: [
        require("@/assets/img/homePage/banner.jpg"),
        require("@/assets/img/homePage/banner(1).jpg"),
        require("@/assets/img/homePage/banner(2).jpg"),
        require("@/assets/img/homePage/banner(3).jpg"),
      ],
    };
  },
};
</script>

<style scoped>
.homePageTop {
  display: flex;
  justify-content: center;
}
.homePageTopContent {
  width: 1144px;
}
.homePageTopContent1 {
  width: 692px;
  height: 409px;
  background-color: #f5f5f5;
  padding: 53px 17px;
  box-sizing: border-box;
  margin-top: 123px;
  position: relative;
}
.homePageTopContent2 {
  display: flex;
  justify-content: flex-end;
}
.homePageTopContent2_img {
  width: 588px;
  height: 385px;
  position: absolute;
  top: -43px;
  left: -450px;
}
.boxPaddingLeft {
  padding-left: 150px;
}
.homePageTopContent1_title {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c49f6f;
}
.homePageTopContent1_english {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-top: 10px;
  margin-bottom: 30px;
}
.homePageTopContent1_content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  width: 536px;
}
.homePageTopContent1_end {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 24px;
}
.homePageTopContent1_img {
  width: 588px;
  height: 385px;
  position: absolute;
  top: -43px;
  right: -460px;
}
.productBoxfather {
  background-color: #f5f5f5;
  padding-top: 16px;
  padding-bottom: 80px;
  display: flex;
  justify-content: center;
}
.productBox {
  display: flex;
}
.productBoxRight_text {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c49f6f;
}
.productBoxRight_dsc {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
  margin-bottom: 22px;
}
.productBoxRight_content {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  width: 556px;
  height: 63px;
}
.productBoxRight_btn {
  width: 120px;
  height: 32px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  text-align: center;
  background-color: #c49f6f;
  border-radius: 8px;
  margin-top: 37px;
  margin-bottom: 23px;
  cursor: pointer;
}
.productBoxRight_tag {
  display: flex;
}
.productBoxRight_tag_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.productBoxRight_tag_box_text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c49f6f;
  line-height: 24px;
  margin-top: 14px;
}
.productBoxRight_tag_box_pad {
  padding-left: 58px;
  padding-right: 58px;
}
</style>
