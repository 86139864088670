<template>
  <div id="app">
    <div class="navBarFather">
      <navBar></navBar>
    </div>
    <div class="routerviewBox">
      <router-view></router-view>
    </div>
    <div class="gotop" @mouseover="mouseover" @mouseout="mouseout" @click="click">
      <img :src="src" style="width:50px;height:50px" alt="">
    </div>
  </div>
</template>

<script>
import navBar from "./components/navBar.vue";
export default {
  name: "App",
  components: {
    navBar,
  },
  created(){
    // document.title = '百麗春'
     document.title = '深圳市前海胶粘天下信息科技有限公司'
  },
  data(){
    return{
      src:require('./assets/img/top1.png')
    }
  },
  methods:{
    mouseover(){
      this.src = require('./assets/img/top.png')
    },
    mouseout(){
       this.src = require('./assets/img/top1.png')
    },
    click(){
       document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style>
body {
  margin: 0;
  position: relative;
}
.navBarFather {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
}
.routerviewBox {
  padding-top: 104px;
}
.gotop{
  position: fixed;
  bottom: 150px;
  right: 50px;
  cursor: pointer;
  z-index: 10000;
}
</style>
