<template>
  <div>
    <div style="padding-top: 87px; padding-bottom: 108px">
      <mytitle title="研发及生产工艺" FiexText="R & D PROCESS"></mytitle>
    </div>
    <div class="research">
      <div class="researchcontent">
        <div class="researchBox1">
          <!-- <div class="researchBox1text">
            BAILICHUN护肤品以瑞⼠进⼝配⽅采⽤超纯⽔为载体，瑞⼠国花⾼⼭⽕绒草为等渗体，超低分⼦量携带专利功效成分、靶向定位直达细胞线粒体（细胞动力工厂）经呼吸作用产生能量，直达病灶、针对治疗。
          </div> -->
          <div class="researchBox1text">
            以瑞⼠进⼝配⽅采⽤超纯⽔为载体，瑞⼠国花⾼⼭⽕绒草为等渗体，超低分⼦量携带专利功效成分、靶向定位直达细胞线粒体（细胞动力工厂）经呼吸作用产生能量，直达病灶、针对治疗。
          </div>
          <img
            src="../../assets/img/research/3.png"
            alt=""
            style="width: 560px;height:350px"
          />
        </div>
        <div class="researchBox2">
          <img
            src="../../assets/img/research/2.png"
            alt=""
            style="width: 1180px"
          />
        </div>
        <div style="height:138px"></div>
        <div class="researchBox1">
          <img
            src="../../assets/img/research/1.png"
            alt=""
            style="width: 560px;height:350px"
          />
          <div class="researchBox1text">
            ⽣产采⽤药品⽣产标准，医药洁净⽆尘⻋间、全⾃动⽆菌灌装。⽣产⼯艺符合欧盟双体系认证+美国⾷品药品监督管理局（FDA)+NDC(可进入美国药店认证级别)
          </div>
        </div>
         <div style="height:158px"></div>
      </div>
    </div>

    <div>
      <foolerBar></foolerBar>
    </div>
  </div>
</template>

<script>
import mytitle from "../../components/title.vue";

import foolerBar from "../../components/foolerBar.vue";
export default {
  components: {
    foolerBar,
    mytitle,
  },
  methods: {},
  data() {
    return {
      imgData: [
        require("@/assets/img/homePage/banner.jpg"),
        require("@/assets/img/homePage/banner(1).jpg"),
        require("@/assets/img/homePage/banner(2).jpg"),
        require("@/assets/img/homePage/banner(3).jpg"),
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.research {
  display: flex;
  justify-content: center;
  .researchcontent {
    width: 1180px;
    .researchBox1 {
      display: flex;
      justify-content: space-between;
      .researchBox1text {
        width: 568px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #666666;
        line-height: 48px;
        margin-top: 24px;
      }
    }
    .researchBox2 {
      margin-top: 138px;
    }
  }
}
</style>>


