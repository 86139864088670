<template>
  <div>
    <el-carousel :interval="5000" arrow="always" height="645px">
      <el-carousel-item v-for="item in data" :key="item">
        <img :src="item" alt="" style="width:100%">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },
  created(){
      console.log(this.data)
  },
  components: {},
};
</script>

<style>
.el-carousel__arrow{
    border-radius: 0 !important;
    background-color: rgb(204, 187, 167) !important
}
.el-carousel__button{
  background-color:#C49F6F !important
}
</style>
