<template>
  <div class="titleBox">
    <div class="titleBoxText">
      {{ title }}
    </div>
    <div class="titleBoxBorder"></div>
    <div class="titleBoxFiexText" v-if="FiexText">
        {{FiexText}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    FiexText:{
        default:null,
        type:String
    }
  },
  components: {},
};
</script>

<style>
.titleBox {
     position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titleBoxText {
   z-index: 500;
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c49f6f;
  padding: 23px;
}
.titleBoxBorder {
  width: 360px;
  height: 2px;
  background-color: #c49f6f;
  border-radius: 50%;
}
.titleBoxFiexText {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e8e8e8;
  position: absolute;
  top: 0;
  
 
}
</style>
