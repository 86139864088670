<template>
  <div class="navBox">
    <div class="navBoxCenter">
      <img src="../assets/img/logo.png" style="width:123px;height:44px">
      <div class="skipBoxfather">
        <skipBar></skipBar>
      </div>
    </div>
  </div>
</template>

<script>
import skipBar from "./skipBar.vue"
export default {
  components: {
    skipBar
  },
};
</script>

<style>
.navBox {
  height: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #C49F6F;
}
.navBoxCenter {
  width: 1180px;
  display: flex;
  align-items: center;
}
.skipBoxfather{
  margin-left: 215px;
}
</style>
