<template>
  <div>
    <sweiper :data="imgData"></sweiper>
    <div class="edelweissfatherBox">
      <div class="edelweissBox">
        <div class="edelweissBoxtitle">
          <div class="edelweissBoxtitle_left">雪绒花系列</div>
          <div class="edelweissBoxtitle_right" @click="clickFn">铂金系列</div>
        </div>
        <div class="edelweissBoxcontent">
          <div v-for="(item, index) in data" :key="index">
            <div class="itemsku">
              <img :src="item.img" alt="" style="width: 373px; height: 385px" />
              <div class="itemskuBOx">
                <div class="itemskuBOx_top">雪绒花系列</div>
                <div class="itemskuBOx_center">{{ item.dec }}</div>
                <div class="itemskuBOx_bottom">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <foolerBar></foolerBar>
    </div>
  </div>
</template>

<script>
import sweiper from "../../components/sweiper.vue";
import foolerBar from "../../components/foolerBar.vue";
export default {
  components: {
    sweiper,
    foolerBar,
  },
  created() {},
  methods: {
    clickFn() {
      this.$store.commit("changeSkipBarNum", 2);
      this.$router.push({
        path: "platinum",
      });
    },
  },

  data() {
    return {
      imgData: [
        require("@/assets/img/homePage/banner.jpg"),
        require("@/assets/img/homePage/banner(1).jpg"),
        require("@/assets/img/homePage/banner(2).jpg"),
        require("@/assets/img/homePage/banner(3).jpg"),
      ],
      data: [
        {
          img: require("../../assets/img/edelweiss/1.png"),
          dec: "保湿修护、告别干燥肌",
          content: "白色套盒",
        },
        {
          img: require("../../assets/img/edelweiss/2.png"),
          dec: "不用担心补水",
          content: "雪绒花舒润修护面膜",
        },
        {
          img: require("../../assets/img/edelweiss/3.png"),
          dec: "激活细胞、加速细胞代谢、促进肌肤营养吸收",
          content: "冻干粉",
        },
        {
          img: require("../../assets/img/edelweiss/4.png"),
          dec: "3D立体补水、充盈细胞间质",
          content: "透水导流肌源精华液",
        },
        {
          img: require("../../assets/img/edelweiss/5.png"),
          dec: "等渗导流、增强肌肤免疫力、打通肌肤水通道",
          content: "界面循环导流液",
        },
        {
          img: require("../../assets/img/edelweiss/6.png"),
          dec: "微乳化工艺、滋养棘层嫩滑肌肤",
          content: "肌源渗透微型乳",
        },
        {
          img: require("../../assets/img/edelweiss/7.png"),
          dec: "全氨基酸洁面、清除污垢疏通毛孔、水润焕彩",
          content: "醇柔净透洁面乳",
        },
      ],
    };
  },
};
</script>

<style scoped>
.edelweissfatherBox {
  display: flex;
  justify-content: center;
}
.edelweissBox {
  width: 1180px;
}
.edelweissBoxtitle {
  display: flex;
  margin-top: 88px;
}
.edelweissBoxcontent {
  margin-top: 44px;
  display: grid;
  grid-template-columns: 373px 373px 373px;
  grid-template-rows: repeat(3, 33.33%);
  grid-gap: 33px 30px;
  padding-bottom: 158px;
}
.itemsku {
  width: 373px;

  box-shadow: 0px 0px 10px #d6d6d6;
}
.edelweissBoxtitle_left {
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c49f6f;
  border-bottom: 2px solid #c49f6f;
  padding: 12px 0;
  cursor: pointer;
}
.edelweissBoxtitle_right {
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #666666;
  padding: 12px 0;
  border-bottom: 2px solid #666666;
  cursor: pointer;
  margin-left: 35px;
}
.itemskuBOx {
  padding: 27px 0 18px 18px;
}
.itemskuBOx_top {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c49f6f;
}
.itemskuBOx_center {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #666666;
  margin-top: 12px;
  margin-bottom: 23px;
}
.itemskuBOx_bottom {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
</style>
