<template>
  <div>
    <sweiper :data="imgData"></sweiper>
    <div class="edelweissfatherBox">
      <div class="edelweissBox">
        <div class="edelweissBoxtitle">
          <div class="edelweissBoxtitle_left1" @click="clickFn">雪绒花系列</div>
          <div class="edelweissBoxtitle_right1">铂金系列</div>
        </div>
        <div class="edelweissBoxcontent">
          <div v-for="(item, index) in data" :key="index">
            <div class="itemsku">
              <img :src="item.img" alt="" style="width: 373px; height: 385px" />
              <div class="itemskuBOx">
                <div class="itemskuBOx_top">铂金系列</div>
                <div class="itemskuBOx_center">{{ item.dec }}</div>
                <div class="itemskuBOx_bottom">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <foolerBar></foolerBar>
    </div>
  </div>
</template>

<script>
import sweiper from "../../components/sweiper.vue";
import foolerBar from "../../components/foolerBar.vue";
export default {
  components: {
    sweiper,
    foolerBar,
  },
  methods: {
    clickFn() {
      this.$store.commit("changeSkipBarNum", 1);
        this.$router.push({
            path:'edelweiss'
        })
    },
  },
  data() {
    return {
      imgData: [
        require("@/assets/img/homePage/banner.jpg"),
        require("@/assets/img/homePage/banner(1).jpg"),
        require("@/assets/img/homePage/banner(2).jpg"),
        require("@/assets/img/homePage/banner(3).jpg"),
      ],
      data: [
        {
          img: require("../../assets/img/platinum/1.png"),
          dec: "深透补水、修护肌肤屏障",
          content: "界面循环导流液",
        },
        {
          img: require("../../assets/img/platinum/2.png"),
          dec: "强效补水渗透肌肤 开启肌肤微循环系统",
          content: "界面循环柔肤液",
        },
        {
          img: require("../../assets/img/platinum/3.png"),
          dec: "修护肌肤屏障、加速新陈代谢、促进胶原再生",
          content: "依克多因静肤精华霜",
        },
        {
          img: require("../../assets/img/platinum/4.png"),
          dec: "提高肌肤免疫力、消炎去敏提亮肤色",
          content: "白菊内酯静颜舒肌母液",
        },
        {
          img: require("../../assets/img/platinum/5.png"),
          dec: "修护断裂胶原、促进细胞再生、紧实细滑肌肤",
          content: "抗衰抚纹精华液",
        },
        {
          img: require("../../assets/img/platinum/6.png"),
          dec: "专注眼部娇弱肌肤、抚平细纹、紧实明眸修护",
          content: "眼部抗衰优肌精华液",
        },
        {
          img: require("../../assets/img/platinum/7.png"),
          dec: "细胞导入式抗衰致套餐",
          content: "黑色礼盒",
        },
        {
          img: require("../../assets/img/platinum/7.png"),
          dec: "洁面",
          content: "抗衰抚纹精粹面膜",
        },
      ],
    };
  },
};
</script>

<style scoped>
.edelweissfatherBox {
  display: flex;
  justify-content: center;
}
.edelweissBox {
  width: 1180px;
}
.edelweissBoxtitle {
  display: flex;
  margin-top: 88px;
}
.edelweissBoxcontent {
  margin-top: 44px;
  display: grid;
  grid-template-columns: 373px 373px 373px;
  grid-template-rows: repeat(3, 33.33%);
  grid-gap: 33px 30px;
  padding-bottom: 158px;
}
.itemsku {
  width: 373px;
  box-shadow: 0px 0px 10px #d6d6d6;
}
.edelweissBoxtitle_left1 {
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #666666;
  padding: 12px 0;
  border-bottom: 2px solid #666666;
  cursor: pointer;
}
.edelweissBoxtitle_right1 {
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c49f6f;
  border-bottom: 2px solid #c49f6f;
  padding: 12px 0;
  cursor: pointer;

  margin-left: 35px;
}
.itemskuBOx {
  padding: 27px 0 18px 18px;
}
.itemskuBOx_top {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #c49f6f;
}
.itemskuBOx_center {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #666666;
  margin-top: 12px;
  margin-bottom: 23px;
}
.itemskuBOx_bottom {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
</style>
