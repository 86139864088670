import Vue from 'vue'
import Vuex from 'vuex'

//挂载Vuex
Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
    state:{
        //存放的键值对就是所要管理的状态
        num:0
    },
    mutations:{
        changeSkipBarNum(state,payload){ //修改nav选中按钮
            this.state.num = payload
        }
    }
})

export default store