import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
Vue.config.productionTip = false
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import store from './store'
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
